import client from "../config/axios"
import { TaskPollResponse } from "./faqService"

export type DocumentsResponse = { documents: WalluDocument[] }

export interface WalluDocument {
  id?: string
  guild_id: number
  name: string
  type: "DOCUMENTATION" | "BOT_ARCHIVE" | "GUILD_DESCRIPTION" | "SELF_TRAINING" | "PRIORITY_FALLBACK_DOCUMENT"
  created_at: string
  content: string
  description: string
  updatedContent: string | undefined
  read_only: boolean
  active: boolean
  meta: {
    enabled_channels: string[]
    fully_indexed_at?: string
    scrape_task_id?: string
    update_failed_at?: string
    source: {
      source_type: string
      source_channel?: string
      only_roles: string[],
      max_message_age_days: number,
      reference_message_ignores_roles: boolean,
      source_url?: string
      follow_links?: boolean
    } | null
  }
}


export async function saveDocuments(guildId: string, documents: Partial<WalluDocument>[]): Promise<DocumentsResponse> {
  documents.forEach(doc => {
    // save bandwidth
    if (doc.read_only) doc.content = undefined
  })
  const res = await client.post(`/documents/${guildId}`, documents)
  return res.data
}

export async function fetchDocuments(guildId: string): Promise<DocumentsResponse> {
  const res = await client.get(`/documents/${guildId}`)
  return res.data
}

export async function deleteDocument(guildId: string, documentId: string): Promise<DocumentsResponse> {
  const res = await client.delete(`/documents/${guildId}/${documentId}`)
  return res.data
}

export async function pollDocumentProgress(taskId: string): Promise<TaskPollResponse> {
  const res = await client.get(`/tasks/status/process-document/${taskId}`)
  return res.data
}

export async function getPercentageProcessed(guildId: string): Promise<{ processingPercentage: number }> {
  const res = await client.get(`/processing/${guildId}`)
  return res.data
}

/**
 * Request to update document sources (i.e., from discord channels and from website content)
 * @param documentIds if undefined, all documents will be updated
 */
export async function requestDocumentUpdate(guildId: string, documentIds: string[] | undefined = undefined): Promise<DocumentsResponse> {
  const res = await client.post(`/update-sources/${guildId}`, { document_ids: documentIds })
  return res.data
}
