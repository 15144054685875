import React from "react"
import useAppContext from "../context/useAppContext"
import { Button } from "@mui/material"
import { BASE_URL } from "../config/axios"
import PremiumIcon from "@mui/icons-material/WorkspacePremium"
import { PLANS_WEBSITE_URL } from "../config/urls"
import { ServerPlan } from "../model/ServerPlan"

export const ManageSubscriptionButton = () => {

  const { guild, plan } = useAppContext()
  const billingPage = useBillingPageURL(guild?.id);

  if (isPremium(plan)) {
    return (
      <Button
        disabled={!guild?.is_admin}
        title={guild?.is_admin ? undefined : "Only Discord server administrators can manage billing"}
        variant="contained"
        color="primary"
        href={billingPage}
        style={{ backgroundColor: '#00d1b2' }}
        startIcon={<PremiumIcon className="text-violet-800"/>}
        target="_blank"
      >
        Manage Billing
      </Button>
    )
  }

  return (
    <div>
      <GoPremiumButton/>
    </div>
  )
}

export const GoPremiumButton = () => {

  const { billing } = useAppContext()

  return (
    <Button
      className="hover:scale-110"
      href={PLANS_WEBSITE_URL}
      variant="contained"
      style={{ backgroundColor: '#00d1b2', transition: 'transform 0.3s' }}
      startIcon={<PremiumIcon className="text-violet-800"/>}
      target="_blank"
    >
      {billing?.first_subscription_at ? "Renew Subscription!" : "Go Premium!"}
    </Button>
  )
}

export const isPremium = (plan: ServerPlan | undefined): boolean => {
  return !!(plan?.plan_type && ["PREMIUM", "CUSTOM_BOT", "CUSTOM", "PROFESSIONAL", "UNLIMITED"].includes(plan.plan_type))
}

export const useBillingPageURL = (guildId: string) => {
  const { billing } = useAppContext()
  if (billing?.last_subscription_type == "DISCORD") {
    return `https://wallubot.com/discord-billing`
  }
  return `${BASE_URL}/billing/${guildId}`
}
