import React from "react"

interface PrivacyPolicyDisclaimerProps {
  title: string
}

export const PrivacyPolicyTermsDisclaimer = ({ title }: PrivacyPolicyDisclaimerProps) => {

  return (
    <p className="text-gray-300 max-w-[80%] my-8 m-auto">
      <span className="text-xs text-gray-400">
        {title}
        <br/>
        We prohibit the use of these services for any illegal or unethical purposes.
        Check our privacy policy and terms of service for more information.
      </span>
    </p>
  )
}
