import React, { useState } from "react"
import { Controller, useForm, useFormState } from "react-hook-form"
import useAppContext from "../../context/useAppContext"
import { deleteFAQs, upsertFAQs } from "../../service/faqService"
import { Divider, LinearProgress } from "@mui/material"
import SaveButton from "../common/SaveButton"
import ConfidenceSlider from "../common/ConfidenceSlider"

interface EditValues {
  threshold?: number
}

export const MassEditFAQs = () => {

  const [loading, setLoading] = useState(false)

  const { handleSubmit, control, reset } = useForm<EditValues>({ defaultValues: { threshold: 0.75 } })
  const { isDirty } = useFormState({ control })

  const { guild, faqs, reloadConfig, setFAQs, serverData } = useAppContext()

  const update = async (values: EditValues) => {
    try {
      if (window.confirm("Are you sure? This will affect all FAQs")) {
        setLoading(true)
        const newFaqs = faqs.map(faq => ({
          ...faq,
          threshold: values.threshold ?? faq.threshold,
        }))
        const saved = await upsertFAQs(guild!!.id, newFaqs)
        setFAQs(saved.faqs)
        reloadConfig()
        reset()
        setLoading(false)
      }
    } catch (err) {
      window.alert("Error updating FAQs")
    }
  }

  const deleteAll = async () => {
    try {
      if (window.confirm("Are you sure? This will delete all FAQs")) {
        setLoading(true)
        const saved = await deleteFAQs(guild!!.id, 'all')
        setFAQs(saved.faqs)
        reloadConfig()
        reset()
        setLoading(false)
      }
    } catch (err) {
      window.alert("Error deleting FAQs")
    }
  }

  // average threshold of all FAQs (or 0.75 if no FAQs)
  const defaultValue = faqs.length ? faqs.reduce((acc, faq) => acc + faq.threshold, 0) / faqs.length : 0.75

  return (
    <div>
      <h1 className="text-2xl my-2">Edit All FAQs at once</h1>
      <div className="my-4 text-gray-300">
        This will update all FAQs at once. You cannot undo these changes.
        <br/>
        <br/>
        <p>
          NOTE: after editing, go to FAQs page to see the changes. The slider will reset to 0.8, but the changes will be saved!
        </p>
      </div>
      <form className="flex flex-col items-center w-full mt-12" onSubmit={handleSubmit(update)}>
        <Controller
          name="threshold"
          defaultValue={defaultValue}
          control={control}
          render={({ field }) => (
            <ConfidenceSlider className="w-4/5" field={field}/>
          )}
        />
        <div className="flex flex-row justify-end items-center space-x-4 mt-10">
          <SaveButton
            disabled={loading}
            onClick={handleSubmit(update)}
          />
        </div>

        <div className="w-[100%] my-24">
          <Divider/>
        </div>

        <div className="border-2 border-red-500 rounded-lg p-4 w-full">
          <h1 className="text-2xl my-2">Danger Zone</h1>
          <p className="my-4 text-gray-300">
            You cannot undo this action.
          </p>
          <button
            className="bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded disabled:bg-gray-400 disabled:cursor-not-allowed"
            disabled={loading || !faqs.length}
            onClick={deleteAll}
            title={faqs.length ? "Delete all FAQs" : "No FAQs to delete"}
          >
            Delete All FAQs
          </button>
        </div>

        {loading && <LinearProgress className="w-full mt-4"/>}
      </form>
    </div>
  )
}
